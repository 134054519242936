<template>
  <div class="mt-4">
    <v-dialog v-if="showComprovante" v-model="showComprovante">
      <comprovante
        :classificacaoID="classificacaoID"
        @cancelar="showComprovante = false"
      />
    </v-dialog>

    <v-dialog v-if="showModal" v-model="showModal">
      <classificacao-modal
        :pedidoAgendado="pedidoAgendado"
        :origem="origem"
        :classificacaoID="classificacaoID"
        @cancelar="showModal = false"
      />
    </v-dialog>
    <v-dialog v-if="motivoModal" max-width="600px" v-model="motivoModal"
      ><reprovar-classificacao
        :classificacao="classificacao"
        @cancelar="
          () => {
            motivoModal = false
            reprovacao = {
              classificacao: {},
            }
          }
        "
        @deleteItem="(item) => $emit('deleteItem', item.public_id)"
      ></reprovar-classificacao
    ></v-dialog>

    <confirm-dialog @confirm="confirmDeleteClassificacao" />
    <confirm-dialog @confirm="confirmRefugarClassificacao" />

    <v-data-table
      :headers="headers"
      :search="search"
      :items="orderedListagem"
      sort-by="descricao"
      class="border"
    >
      <template v-slot:[`item.agendamento.status`]="{ item }">
        <v-chip color="primary">{{ item.agendamento.status }}</v-chip>
      </template>
      <template v-slot:[`item.tipo`]="{ item }">
        {{ item.origem === null ? 'Origem' : 'Destino' }}
      </template>
      <template v-slot:[`item.agendamento.agendado_em`]="{ item }">
        {{
          item.agendamento.agendado_em
            ? new Date(item.agendamento.agendado_em).toLocaleString()
            : '-'
        }}
      </template>
      <template v-slot:[`item.agendamento.triado_em`]="{ item }">
        {{
          item.agendamento.triado_em
            ? new Date(item.agendamento.triado_em).toLocaleString()
            : '-'
        }}
      </template>
      <template v-slot:[`item.agendamento.tempoEmPatio`]="{ item }">
        {{ getTempoEmPatio(item.agendamento) }}
      </template>
      <template
        v-slot:[`item.agendamento.pedidos_agendados.numero_pedido`]="{ item }"
      >
        <div class="div-pedido-info">
          <p
            v-for="({ pedido }, index) in item.agendamento.pedidos_agendados"
            :key="index"
          >
            {{ pedido.numero_pedido }}
          </p>
        </div>
      </template>
      <template
        v-slot:[`item.agendamento.pedidos_agendados.produto`]="{ item }"
      >
        <div class="div-pedido-info">
          <p
            v-for="({ pedido }, index) in item.agendamento.pedidos_agendados"
            :key="index"
          >
            {{ pedido.produto.descricao }}
          </p>
        </div>
      </template>
      <template
        v-slot:[`item.agendamento.pedidos_agendados.subProduto`]="{ item }"
      >
        <div class="div-pedido-info">
          <p
            v-for="({ pedido }, index) in item.agendamento.pedidos_agendados"
            :key="index"
          >
            {{ pedido.subProduto.descricao }}
          </p>
        </div>
      </template>
      <template
        v-slot:[`item.agendamento.pedidos_agendados.quantidade`]="{ item }"
      >
        <div class="div-pedido-info">
          <p
            v-for="(pedido, index) in item.agendamento.pedidos_agendados"
            :key="index"
          >
            {{ converteKg(pedido.quantidade_agendada) }}
          </p>
        </div>
      </template>
      <template
        v-slot:[`item.agendamento.pedidos_agendados.embalagem`]="{ item }"
      >
        <div class="div-pedido-info">
          <p
            v-for="({ pedido }, index) in item.agendamento.pedidos_agendados"
            :key="index"
          >
            {{ pedido.embalagem.descricao }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="mr-2 mb-2"
              dark
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-hover v-slot="{ hover }">
              <div>
                <!-- <v-list-item
                  v-if="item.agendamento.status == 'CLAssificação (AGUARDANDO ANÁLISE)' && !item.destino && !item.origem && canGenerateClassificacaoDestino" 
                  class="pointer"
                  :class="{ primary: hover, 'white--text': hover }"
                  @click="visualizarModal(item)"
                >
                  Gerar Classificação Destino
                </v-list-item> -->
                <v-list-item
                  class="pointer"
                  v-if="
                    item.agendamento.status ==
                      'CLAssificação (AGUARDANDO ANÁLISE)' &&
                    !item.destino &&
                    !item.origem &&
                    canGenerateClassificacaoDestino
                  "
                  :class="{ primary: hover, 'white--text': hover }"
                  @click="refugar(item)"
                >
                  Refugar
                </v-list-item>
              </div>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <div>
                <v-list-item
                  v-if="canManageClassificacaoOrigem"
                  class="pointer"
                  :class="{ primary: hover, 'white--text': hover }"
                  @click="editarClassificacao(item)"
                >
                  Editar Classificação
                </v-list-item>
              </div>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <div>
                <v-list-item
                  v-if="
                    item.agendamento.status == 'REFUGADO' && canRetornarAOrigem
                  "
                  class="pointer"
                  :class="{ primary: hover, 'white--text': hover }"
                  @click="reprovar(item)"
                >
                  Retornar a origem
                </v-list-item>
              </div>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <div>
                <v-list-item
                  v-if="
                    (item.agendamento.status == 'REFUGADO' ||
                      item.agendamento.status ==
                        'CLAssificação (AGUARDANDO ANÁLISE)') &&
                    canAprovarClassificacao
                  "
                  class="pointer"
                  :class="{ primary: hover, 'white--text': hover }"
                  @click="aprovar(item)"
                >
                  Aprovado
                </v-list-item>
              </div>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <div>
                <v-list-item
                  v-if="canVisualizarComprovanteClassificacao"
                  class="pointer"
                  :class="{ primary: hover, 'white--text': hover }"
                  @click="visualizarComprovante(item.public_id)"
                >
                  Visualizar Comprovante
                </v-list-item>
              </div>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <div>
                <v-list-item
                  v-if="canManageClassificacaoOrigem"
                  class="pointer"
                  :class="{ error: hover, 'white--text': hover }"
                  @click="removerClassificacao(item)"
                >
                  Remover Classificação
                </v-list-item>
              </div>
            </v-hover>
          </v-list>
        </v-menu>
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { props } from '../GerenciamentoServico/listagem/_props'
import { data as _data } from '../GerenciamentoServico/listagem/_data'
import { computed } from '../GerenciamentoServico/listagem/_computed'
import { methods } from '../GerenciamentoServico/listagem/_methods'
import Comprovante from './Comprovante.vue'
import ReprovarClassificacao from './ReprovarClassificacao.vue'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import ClassificacaoModal from '../NovoAgendamento/Etapas/classificacao/ClassificacaoModal.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import {
  BasicPermissions,
  CustomPermissions,
  Sequences,
} from '@/utils/permissions'

export default {
  components: {
    Comprovante,
    ClassificacaoModal,
    ReprovarClassificacao,
    ConfirmDialog,
  },
  props,
  data() {
    return {
      ..._data(),
      showComprovante: false,
      showModal: false,
      motivoModal: false,
      classificacao: {},
      classificacaoID: '',
      classificacaoPublicIdRefugar: '',
      pedidoAgendado: {},
      origem: null,
      pesquisa: '',
    }
  },
  computed: {
    ...computed,
    ...mapState('classificacao', ['itemToDelete']),

    permissions() {
      return Sequences.Classificacao.toString()
    },

    canGenerateClassificacaoDestino() {
      return this.$canDo(
        CustomPermissions.GENERATE_CLASSIFICACAO_DESTINO,
        this.permissions
      )
    },

    canManageClassificacaoOrigem() {
      return this.$canDo(
        CustomPermissions.MANAGE_CLASSIFICACAO_ORIGEM,
        this.permissions
      )
    },

    canAprovarClassificacao() {
      return this.$canDo(
        CustomPermissions.APROVAR_CLASSIFICACAO_ORIGEM,
        this.permissions
      )
    },

    canVisualizarComprovanteClassificacao() {
      return this.$canDo(
        CustomPermissions.VISUALIZAR_COMPROVANTE_CLASSIFICACAO_ORIGEM,
        this.permissions
      )
    },

    canRetornarAOrigem() {
      return this.$canDo(CustomPermissions.RETORNAR_A_ORIGEM, this.permissions)
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permissions)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permissions)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permissions)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permissions)
    },
  },
  methods: {
    ...methods,
    ...mapActions('patio', [
      'nextStep',
      'enviaClassificacao',
      'resetarClassificacao',
    ]),
    ...mapActions('template', ['setDialogMessage']),
    ...mapActions('classificacao', [
      'deleteClassificacao',
      'aprovarClassificacao',
      'editClassificacao',
    ]),
    ...mapMutations('classificacao', ['SET_ITEM_TO_DELETE']),
    visualizarModal({ pedido_agendado, public_id }) {
      this.showModal = true
      this.pedidoAgendado = pedido_agendado
      this.origem = public_id
      this.classificacaoID = null
    },

    refugar(item) {
      this.SET_ITEM_TO_DELETE(item)
      this.setDialogMessage({
        title: 'Confirmar refugo?',
        message: 'Deseja mesmo refugar essa classificação?',
      })
    },
    async confirmRefugarClassificacao() {
      try {
        const refugar = true
        const caracteristica = []

        const dados = {
          caracteristica,
          refugar,
        }
        await this.editClassificacao({
          classificacaoID: this.itemToDelete.public_id,
          dados,
        })
        this.successMessage('Classificacação refugada com sucesso')
        location.reload()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    visualizarComprovante(classificacaoID) {
      this.showComprovante = true
      this.classificacaoID = classificacaoID
    },
    editarClassificacao({ pedido_agendado, public_id }) {
      this.showModal = true
      this.pedidoAgendado = pedido_agendado
      this.classificacaoID = public_id
    },
    reprovar(item) {
      this.classificacao = { ...item }
      this.motivoModal = true
    },
    async aprovar(item) {
      try {
        await this.aprovarClassificacao({
          classificacaoID: item.public_id,
        })
        this.$emit('deleteItem', item.public_id)
        this.successMessage('Etapa avançada!')
      } catch (err) {
        window.err = err
        if (err.response) {
          this.errorMessage(err.response.data)
        } else {
          this.errorMessage(err)
        }
      }
    },

    removerClassificacao(item) {
      this.SET_ITEM_TO_DELETE(item)
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja mesmo excluir essa classificação?',
      })
    },
    async confirmDeleteClassificacao() {
      try {
        await this.deleteClassificacao({
          classificacaoID: this.itemToDelete.public_id,
        })
        this.successMessage('Classificação excluída com sucesso!')
        location.reload()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    async handleNextStep(item) {
      try {
        const { status, prestador } = await this.nextStep(item.agendamento)
        item.agendamento.status = status
        item.agendamento.etapa_atual = { prestador }

        this.successMessage('Etapa avançada!')
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async handleEnviaClassificacao(item) {
      try {
        await this.enviaClassificacao(item.agendamento)

        this.successMessage('Enviado para classificação!')
      } catch (err) {
        this.errorMessage(err)
      }
    },
    async handleResetarClassificacao(item) {
      try {
        await this.resetarClassificacao(item.agendamento)

        this.successMessage('Classificação resetada!')
      } catch (err) {
        this.errorMessage(err)
      }
    },
  },
  watch: {
    'empresaAtual.public_id': {
      handler() {
        this.$emit('cleanData')
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.status--cancelado {
  padding: 10px 15px;
  background: rgb(243, 72, 72);
  border-radius: 10px;
  color: white;
}
.status--triagem {
  padding: 10px 15px;
  background: rgb(64, 228, 64);
  border-radius: 10px;
}
.pointer {
  cursor: pointer;
}
.button-submit-orange {
  background: red;
}
.div-pedido-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.div-pedido-info p {
  margin: 5px 0;
}
</style>
