<template>
  <v-card class="pa-4">
    <div class="d-flex justify-space-between">
      <v-btn color="error" @click="$emit('cancelar')">Fechar</v-btn>
      <v-btn color="primary" @click="imprimir">Imprimir</v-btn>
    </div>
    <v-card-title class="d-flex justify-center">
      Laudo de Acompanhamento de Embarque
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3" v-for="item in items" :key="item.text">
          <span>
            <strong>{{ item.text }}</strong>
          </span>
          <p v-if="item.text.toLowerCase() === 'peso'">
            {{ getValue(classificacao, item.value) }} Kg
          </p>
          <p v-else-if="item.text.toLowerCase() === 'cnpj'">
            {{
              getValue(classificacao, item.value) | VMask('##.###.###/####-##')
            }}
          </p>
          <p v-else-if="item.text.toLowerCase() === 'cpf motorista'">
            {{ getValue(classificacao, item.value) | VMask('###.###.###-##') }}
          </p>
          <p v-else-if="item.text.toLowerCase() === 'data da classificação'">
            {{ getValue(classificacao, item.value) | toLocaleDateTimeString }}
          </p>
          <p v-else>{{ getValue(classificacao, item.value) }}</p>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- classificacoes -->
    <v-card-title class="d-flex justify-center">Classificações</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="3"
          v-for="classificacao in classificacao.classificacoes"
          :key="classificacao.caracteristica"
        >
          <span>
            <strong>{{ classificacao.caracteristica }}</strong>
          </span>
          <p>{{ classificacao.valor }} {{ classificacao.unidade_medida }}</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

import { toLocaleDateTimeString } from '@/mixins/convertion'

export default {
  name: 'Comprovante',
  props: {
    classificacaoID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      classificacao: {},
      items: [
        {
          text: 'Cliente',
          value: 'agendamento.cliente.business_name',
        },
        {
          text: 'CNPJ',
          value: 'agendamento.cliente.cnpj',
        },
        {
          text: 'Classificador',
          value: 'classificado_por',
        },
        {
          text: 'Data da Classificação',
          value: 'classificado_em',
        },
        {
          text: 'Ordem de Serviço',
          value: '',
        },
        {
          text: 'Produto',
          value: 'pedido_agendado.pedido.produto.descricao',
        },
        {
          text: 'Placa',
          value: 'agendamento.veiculo.placa_cavalo',
        },
        {
          text: 'Peso',
          value: 'pedido_agendado.quantidade_agendada',
        },
        {
          text: 'Nota Fiscal',
          value: 'agendamento.numero_documento',
        },
        {
          text: 'Ordem Carregamento',
          value: '',
        },
        {
          text: 'Origem',
          value: 'agendamento.remetente.business_name',
        },
        {
          text: 'Local de Embarque',
          value: 'agendamento.terminal.contratado.business_name',
        },
        {
          text: 'Destino',
          value: 'agendamento.destinatario.business_name',
        },
        {
          text: 'Transportadora',
          value: 'agendamento.transportadora.business_name',
        },
        {
          text: 'Motorista',
          value: 'agendamento.motorista.user.name',
        },
        {
          text: 'CPF Motorista',
          value: 'agendamento.motorista.user.cpf',
        },
        {
          text: 'CNH Motorista',
          value: 'agendamento.motorista.user.cnh_registro',
        },
        {
          text: 'Observações',
          value: '',
        },
      ],
    }
  },
  filters: {
    toLocaleDateTimeString,
  },
  methods: {
    ...mapActions('classificacao', ['getClassificacaoComprovante']),

    getValue(item, props) {
      return props.split('.').reduce((acc, prop) => acc[prop], item)
    },

    imprimir() {
      window.print()
    },
  },
  async created() {
    try {
      this.classificacao = await this.getClassificacaoComprovante({
        classificacaoID: this.classificacaoID,
      })
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
</script>

<style lang="scss" scoped>
p {
  text-transform: uppercase;
}
</style>
