<template>
  <div>
    <listagem
      :search="search"
      :headers="headers"
      :data="data"
      :dataTotal="dataTotal"
      :pageSize="page_size"
      @getItemsByPage="handleGetAgendamentos"
      @getItemsByItemsPerPage="handleGetAgendamentos"
      @cleanData="data = []"
      @deleteItem="deleteItem"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Listagem from './Listagem.vue'

export default {
  name: 'ListarClassificacaoRefugada',
  props: {
    search: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  components: {
    Listagem,
  },
  data() {
    return {
      data: [],
      page_size: 15,
      dataTotal: 0,
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('classificacao', ['refugados']),
  },
  methods: {
    ...mapActions('patio', ['getAllAgendamentosByParams']),
    ...mapActions('template', ['errorMessage']),
    ...mapActions('classificacao', ['listRefugados']),

    deleteItem(id) {
      const i = this.data.findIndex((c) => c.public_id === id)
      this.data.splice(i, 1)
    },

    async handleGetAgendamentos(page = 1, new_page_size = undefined) {
      if (new_page_size) {
        this.page_size = new_page_size
      }
      const page_size = this.page_size
      this.$emit('loading')
      try {
        const { results, count } = await this.listRefugados({
          params: {
            page,
            page_size: (page_size === -1 && this.dataTotal) || page_size,
            proprietario: this.empresaAtual.public_id,
          },
        })
        this.data = results
        this.dataTotal = count
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      } finally {
        this.$emit('loaded')
      }
    },
  },
}
</script>
