<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-tabs v-model="tab">
      <v-tab href="#origem" @change="carregarConteudo('origem')">Origem</v-tab>
      <v-tab href="#destino" @change="carregarConteudo('destino')"
        >Destino</v-tab
      >
      <v-tab href="#refugados" @change="carregarConteudo('refugados')"
        >Refugados</v-tab
      >
      <v-tabs-items v-model="tab" class="mt-2">
        <v-tab-item value="origem">
          <listar-classificacao-origem
            :search="search"
            :headers="headers"
            ref="origem"
            @loading="loading = true"
            @loaded="loading = false"
          />
        </v-tab-item>
        <v-tab-item value="destino" eager>
          <listar-classificacao-destino
            :search="search"
            :headers="headers"
            ref="destino"
            @loading="loading = true"
            @loaded="loading = false"
          />
        </v-tab-item>
        <v-tab-item value="refugados" eager>
          <listar-classificacao-refugado
            :search="search"
            :headers="headers"
            ref="refugados"
            @loading="loading = true"
            @loaded="loading = false"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import ListarClassificacaoOrigem from './ListarClassificacaoOrigem.vue'
import ListarClassificacaoDestino from './ListarClassificacaoDestino.vue'
import ListarClassificacaoRefugado from './ListarClassificacaoRefugado.vue'

import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  props: {
    headers: {
      type: Array,
    },
    search: {
      type: String,
    },
  },
  components: {
    ListarClassificacaoOrigem,
    ListarClassificacaoDestino,
    ListarClassificacaoRefugado,
  },
  data() {
    return {
      tab: 'origem',
      loading: false,
    }
  },
  methods: {
    async carregarConteudo(tab) {
      await this.$refs[tab].handleGetAgendamentos()
    },
    async handleGetAgendamentos() {
      await this.carregarConteudo(this.tab)
    },
  },
  computed: {
    permission() {
      return Sequences.Classificacao.toString()
    },

    canList() {
      return this.$canDo(
        BasicPermissions.VISUALIZAR,
        this.permission
      )
    },
  },
  async mounted() {
    if (this.canList) {
      await this.handleGetAgendamentos()
    }
  },
}
</script>
