<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Gerenciamento de Classificações">
          <v-dialog v-model="showComprovante" width="700px">
            <v-card>
              <comprovante />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovanteAgendamento" fullscreen>
            <v-card>
              <comprovante-agendamento />
            </v-card>
          </v-dialog>

          <v-dialog v-model="showComprovanteLaudo">
            <v-card>
              <comprovante-laudo />
            </v-card>
          </v-dialog>

          <template slot="right">
            <v-text-field
              v-if="canList"
              class="mr-3 mb-1"
              v-model="search"
              label="Pesquisa"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canList"
                  color="primary"
                  dark
                  class="ml-2 mb-2"
                  v-on="on"
                  >Indicadores</v-btn
                >
              </template>
              <indicadores-gerencimento-servico
                :headers="headers"
                localStorageKey="headersClassificacao"
                @close="close"
              />
            </v-dialog>
            <v-btn
              v-if="canList"
              color="primary"
              dark
              class="ml-2 mb-2"
              @click="handleGetAgendamentos"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <listar-classificacao
            :search="search"
            :headers="headersComputed"
            ref="listagem"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ListarClassificacao from '@/components/patio/classificacao/ListarClassificacao.vue'
import IndicadoresGerencimentoServico from '@/components/patio/GerenciamentoServico/IndicadoresGerencimentoServico.vue'
import Comprovante from '@/components/patio/NovoAgendamento/comprovante/Componente.vue'
import ComprovanteAgendamento from '@/components/patio/NovoAgendamento/comprovanteAgendamento/Componente.vue'
import ComprovanteLaudo from '@/components/patio/NovoAgendamento/comprovanteLaudo/Componente.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'GerenciamentoClassificacao',
  provide() {
    return {
      visualizarComprovante: this.visualizarComprovante,
      visualizarComprovanteAgendamento: this.visualizarComprovanteAgendamento,
      visualizarComprovanteLaudo: this.visualizarComprovanteLaudo,
      fecharComprovanteAgendamento: this.fecharComprovanteAgendamento,
      fecharComprovanteTriagem: this.fecharComprovanteTriagem,
    }
  },
  data() {
    return {
      dialog: false,
      showComprovante: false,
      showComprovanteLaudo: false,
      showComprovanteAgendamento: false,
      search: '',
      page: {
        title: 'Gerenciamento de Classificações',
      },
      headers: [
        {
          habilited: true,
          block: true,
          text: 'Ações',
          value: 'actions',
          sortable: false,
        },
        {
          habilited: true,
          block: true,
          text: 'Status',
          value: 'agendamento.status',
        },

        {
          habilited: true,
          block: false,
          text: 'Veículo',
          value: 'agendamento.veiculo.placa_cavalo',
        },
        {
          habilited: true,
          block: false,
          text: 'Tipo Operação',
          value: 'agendamento.tipo_operacao.descricao',
        },
        {
          habilited: true,
          block: false,
          text: 'Triado Em',
          value: 'agendamento.triado_em',
        },
        {
          habilited: true,
          block: false,
          text: 'Tempo em pátio',
          value: 'agendamento.tempoEmPatio',
        },
        {
          habilited: true,
          block: false,
          text: 'SubProduto',
          value: 'agendamento.pedidos_agendados.subProduto',
        },
        {
          habilited: true,
          block: false,
          text: 'Terminal',
          value: 'agendamento.terminal.contratado.trade_name',
        },
        {
          habilited: false,
          block: false,
          text: 'Motorista',
          value: 'agendamento.motorista.user.name',
        },
        {
          habilited: false,
          block: false,
          text: 'Produto',
          value: 'agendamento.pedidos_agendados.produto',
        },
      ],
      breadcrumbs: [
        {
          text: 'Pátio',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Gerenciamento de Classificações',
          disabled: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Classificacao.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    headersComputed() {
      return this.headers.filter((header) => !!header.habilited)
    },
  },
  watch: {
    headersComputed() {
      localStorage.setItem('headersClassificacao', JSON.stringify(this.headers))
    },
  },

  async created() {
    if (this.canList) {
      this.setFiltrosGerenciamentoServicos()
    }
  },

  methods: {
    ...mapActions('template', ['errorMessage']),

    visualizarComprovante() {
      this.showComprovanteAgendamento = false
      this.showComprovanteLaudo = false
      this.showComprovante = true
    },

    visualizarComprovanteAgendamento() {
      this.showComprovante = false
      this.showComprovanteLaudo = false
      this.showComprovanteAgendamento = true
    },

    visualizarComprovanteLaudo() {
      this.showComprovante = false
      this.showComprovanteAgendamento = false
      this.showComprovanteLaudo = true
    },

    fecharComprovanteAgendamento() {
      this.showComprovanteAgendamento = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },

    fecharComprovanteTriagem() {
      this.showComprovante = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },

    async handleGetAgendamentos() {
      const { listagem } = this.$refs
      await listagem.handleGetAgendamentos()
    },

    setFiltrosGerenciamentoServicos() {
      const headersSaved = localStorage.getItem('headersClassificacao')

      const headersSavedParsed = JSON.parse(headersSaved)

      if (
        headersSaved &&
        Array.isArray(headersSavedParsed) &&
        headersSavedParsed.length > 0
      ) {
        const add = this.headers
          .map((header) => {
            if (
              !headersSavedParsed.some(
                (headerAlreadySaved) =>
                  headerAlreadySaved.value === header.value
              )
            )
              return header
          })
          .filter((header) => !!header)

        const continua = headersSavedParsed
          .map((headerAlreadySaved) => {
            if (
              this.headers.some(
                (header) => header.value === headerAlreadySaved.value
              )
            )
              return headerAlreadySaved
          })
          .filter((headerAlreadySaved) => !!headerAlreadySaved)

        this.headers = [...add, ...continua]
        localStorage.setItem(
          'headersClassificacao',
          JSON.stringify([...add, ...continua])
        )
      } else {
        localStorage.setItem(
          'headersClassificacao',
          JSON.stringify(this.headers)
        )
      }
    },

    close() {
      this.dialog = false
    },
  },
  components: {
    ListarClassificacao,
    IndicadoresGerencimentoServico,
    Comprovante,
    ComprovanteAgendamento,
    ComprovanteLaudo,
  },
}
</script>
