var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[(_vm.showComprovante)?_c('v-dialog',{model:{value:(_vm.showComprovante),callback:function ($$v) {_vm.showComprovante=$$v},expression:"showComprovante"}},[_c('comprovante',{attrs:{"classificacaoID":_vm.classificacaoID},on:{"cancelar":function($event){_vm.showComprovante = false}}})],1):_vm._e(),(_vm.showModal)?_c('v-dialog',{model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('classificacao-modal',{attrs:{"pedidoAgendado":_vm.pedidoAgendado,"origem":_vm.origem,"classificacaoID":_vm.classificacaoID},on:{"cancelar":function($event){_vm.showModal = false}}})],1):_vm._e(),(_vm.motivoModal)?_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.motivoModal),callback:function ($$v) {_vm.motivoModal=$$v},expression:"motivoModal"}},[_c('reprovar-classificacao',{attrs:{"classificacao":_vm.classificacao},on:{"cancelar":function () {
          _vm.motivoModal = false
          _vm.reprovacao = {
            classificacao: {},
          }
        },"deleteItem":function (item) { return _vm.$emit('deleteItem', item.public_id); }}})],1):_vm._e(),_c('confirm-dialog',{on:{"confirm":_vm.confirmDeleteClassificacao}}),_c('confirm-dialog',{on:{"confirm":_vm.confirmRefugarClassificacao}}),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.orderedListagem,"sort-by":"descricao"},scopedSlots:_vm._u([{key:"item.agendamento.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.agendamento.status))])]}},{key:"item.tipo",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.origem === null ? 'Origem' : 'Destino')+" ")]}},{key:"item.agendamento.agendado_em",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.agendamento.agendado_em ? new Date(item.agendamento.agendado_em).toLocaleString() : '-')+" ")]}},{key:"item.agendamento.triado_em",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.agendamento.triado_em ? new Date(item.agendamento.triado_em).toLocaleString() : '-')+" ")]}},{key:"item.agendamento.tempoEmPatio",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTempoEmPatio(item.agendamento))+" ")]}},{key:"item.agendamento.pedidos_agendados.numero_pedido",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.agendamento.pedidos_agendados),function(ref,index){
        var pedido = ref.pedido;
return _c('p',{key:index},[_vm._v(" "+_vm._s(pedido.numero_pedido)+" ")])}),0)]}},{key:"item.agendamento.pedidos_agendados.produto",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.agendamento.pedidos_agendados),function(ref,index){
        var pedido = ref.pedido;
return _c('p',{key:index},[_vm._v(" "+_vm._s(pedido.produto.descricao)+" ")])}),0)]}},{key:"item.agendamento.pedidos_agendados.subProduto",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.agendamento.pedidos_agendados),function(ref,index){
        var pedido = ref.pedido;
return _c('p',{key:index},[_vm._v(" "+_vm._s(pedido.subProduto.descricao)+" ")])}),0)]}},{key:"item.agendamento.pedidos_agendados.quantidade",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.agendamento.pedidos_agendados),function(pedido,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(_vm.converteKg(pedido.quantidade_agendada))+" ")])}),0)]}},{key:"item.agendamento.pedidos_agendados.embalagem",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"div-pedido-info"},_vm._l((item.agendamento.pedidos_agendados),function(ref,index){
        var pedido = ref.pedido;
return _c('p',{key:index},[_vm._v(" "+_vm._s(pedido.embalagem.descricao)+" ")])}),0)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mb-2",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('div',[(
                  item.agendamento.status ==
                    'CLAssificação (AGUARDANDO ANÁLISE)' &&
                  !item.destino &&
                  !item.origem &&
                  _vm.canGenerateClassificacaoDestino
                )?_c('v-list-item',{staticClass:"pointer",class:{ primary: hover, 'white--text': hover },on:{"click":function($event){return _vm.refugar(item)}}},[_vm._v(" Refugar ")]):_vm._e()],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('div',[(_vm.canManageClassificacaoOrigem)?_c('v-list-item',{staticClass:"pointer",class:{ primary: hover, 'white--text': hover },on:{"click":function($event){return _vm.editarClassificacao(item)}}},[_vm._v(" Editar Classificação ")]):_vm._e()],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('div',[(
                  item.agendamento.status == 'REFUGADO' && _vm.canRetornarAOrigem
                )?_c('v-list-item',{staticClass:"pointer",class:{ primary: hover, 'white--text': hover },on:{"click":function($event){return _vm.reprovar(item)}}},[_vm._v(" Retornar a origem ")]):_vm._e()],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('div',[(
                  (item.agendamento.status == 'REFUGADO' ||
                    item.agendamento.status ==
                      'CLAssificação (AGUARDANDO ANÁLISE)') &&
                  _vm.canAprovarClassificacao
                )?_c('v-list-item',{staticClass:"pointer",class:{ primary: hover, 'white--text': hover },on:{"click":function($event){return _vm.aprovar(item)}}},[_vm._v(" Aprovado ")]):_vm._e()],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('div',[(_vm.canVisualizarComprovanteClassificacao)?_c('v-list-item',{staticClass:"pointer",class:{ primary: hover, 'white--text': hover },on:{"click":function($event){return _vm.visualizarComprovante(item.public_id)}}},[_vm._v(" Visualizar Comprovante ")]):_vm._e()],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('div',[(_vm.canManageClassificacaoOrigem)?_c('v-list-item',{staticClass:"pointer",class:{ error: hover, 'white--text': hover },on:{"click":function($event){return _vm.removerClassificacao(item)}}},[_vm._v(" Remover Classificação ")]):_vm._e()],1)]}}],null,true)})],1)],1)]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }