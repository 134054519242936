<template>
  <validation-observer v-slot="{ invalid }">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Reprovar classificação -
          {{ classificacao.agendamento.veiculo.placa_cavalo }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-row class="mt-0">
          <v-col class="pt-0 d-flex justify-space-between" cols="12">
            <div class="d-flex flex-column my-0">
              <span class="text-caption">{{ classificacao.public_id }}</span>
            </div>
          </v-col>
        </v-row>
        <v-container>
          <v-row>
            <v-col cols="12">
              <validation-provider :name="motivo" v-slot="{ errors }">
                <v-text-field
                  v-model="motivo"
                  :error-messages="errors"
                  label="Motivo da reprovação"
                  prepend-icon="mdi-note-text-outline"
                  hint="Digite o motivo da reprovação."
                  persistent-hint
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelar()"> Cancelar </v-btn>
        <v-btn
          color="blue darken-1"
          :disabled="invalid"
          text
          @click="reprovar()"
        >
          Reprovar
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ReprovarClassificacao',
  props: {
    classificacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      motivo: '',
    }
  },
  methods: {
    ...mapActions('classificacao', ['reprovarClassificacao']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    cancelar() {
      this.motivo = ''
      this.$emit('cancelar')
    },
    async reprovar() {
      const item = this.classificacao
      try {
        const dados = {
          motivo_reprovacao: this.motivo,
        }
        await this.reprovarClassificacao({
          classificacaoID: item.public_id,
          dados,
        })
        this.cancelar()
        this.successMessage('Classificacação reprovada com sucesso')
      } catch (err) {
        if (err.response) {
          this.errorMessage(err.response.data)
        } else {
          this.errorMessage(err)
        }
      }
      this.$emit('deleteItem', item)
    },
  },
  async created() {},
}
</script>
